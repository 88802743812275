<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="2">
          Type
        </v-col>
        <v-col cols="8" sm="8" md="8" lg="3">
          <v-radio-group row v-model="params.type">
            <v-radio label="Balance" :value="true"></v-radio>
            <v-radio label="Not Balance" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="2">
          Date
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="1">
          <v-btn color="primary" @click="search"> <v-icon>mdi-magnify</v-icon></v-btn>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="1">
          <v-btn color="success" @click="excel"> <v-icon>mdi-file-excel</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-text-field
        dense
        flat
        outlined
        background-color="white"
        v-model="filter"
        placeholder="search"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense fixed-header height="500">
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                No
              </th>
              <th>
                Trans #
              </th>
              <th>
                Date
              </th>
              <th>
                Account #
              </th>
              <th>
                Account Name
              </th>
              <th>
                D/C
              </th>
              <th>
                Remark
              </th>
              <th>
                Reff
              </th>
              <th>
                PO#
              </th>
              <th>
                Curr
              </th>
              <th>
                Amount
              </th>
              <th>
                Rates
              </th>
              <th>
                Amount IDR
              </th>
              <th>
                Entry Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in filteredItems"
              :key="item.id"
              :class="changeColor(item.type)"
            >
              <td>{{ index + 1 }}</td>
              <td @click="toFormJournal(item.journalId)">{{ item.journalId }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.accountNumber }}</td>
              <td>{{ item.accountName }}</td>
              <td>{{ item.type === "Debit" ? "D" : "C" }}</td>
              <td>{{ item.remark }}</td>
              <td>{{ item.reference }}</td>
              <td>{{ item.purchaseOrderNumber }}</td>
              <td>{{ item.currency }}</td>
              <td>{{ formatPrice(item.amount) }}</td>
              <td>{{ formatPrice(item.rate) }}</td>
              <td>{{ formatPrice(item.amountIDR) }}</td>
              <td>{{ item.createdAt }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";
export default {
  name: "report-journal",
  computed: {
    filteredItems() {
      const query = this.filter.toLowerCase().trim();

      if (!query) {
        return this.items; // Return all items if the search query is empty
      }

      return this.items.filter(item => {
        return (
          item.journalId.toString().includes(query) ||
          item.date.toLowerCase().includes(query) ||
          item.accountNumber.toString().includes(query) ||
          item.type.toLowerCase().includes(query) ||
          item.currency.toLowerCase().includes(query) ||
          (item.remark && item.remark.toLowerCase().includes(query)) ||
          (item.reference && item.reference.toLowerCase().includes(query)) ||
          item.amount.toString().includes(query) ||
          item.rate.toString().includes(query) ||
          item.amountIDR.toString().includes(query) ||
          item.accountName.toLowerCase().includes(query)
        );
      });
    },
  },
  data() {
    return {
      params: {
        startDate: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        type: true,
      },
      items: [],
      menuStartDate: false,
      menuEndDate: false,
      filter: "",
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changeColor(item) {
      if (item === "Credit") {
        return "change-color";
      }
      return "";
    },
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/journals", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/getExcelJournals", this.params)
        .then(response => {
          fileDownload(response.data, `query-journal.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    toFormJournal(id) {
      const routeData = this.$router.resolve({ name: "Detail Journal", params: { id: id } });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
.change-color {
  background-color: #ffa;
}
</style>
